var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hk-content"},[_c('div',{staticClass:"hk-regist"},[_c('h4',[_vm._v(_vm._s(_vm.$t('login.registeredMember')))]),_c('el-form',{ref:"data",attrs:{"label-position":"left","model":_vm.data,"label-width":"200px"}},[_c('el-form-item',{attrs:{"prop":"companyCn","label":_vm.$t('login.companyNameCn'),"rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.companyNameCnDesc')},model:{value:(_vm.data.companyCn),callback:function ($$v) {_vm.$set(_vm.data, "companyCn", $$v)},expression:"data.companyCn"}})],1),_c('el-form-item',{attrs:{"prop":"companyEn","label":_vm.$t('login.companyNameEn'),"rules":[
				      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.companyNameEnDesc')},model:{value:(_vm.data.companyEn),callback:function ($$v) {_vm.$set(_vm.data, "companyEn", $$v)},expression:"data.companyEn"}})],1),_c('el-form-item',{attrs:{"prop":"number","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' },
			      { pattern: /^\d{11}$/, message: _vm.$t('forms.elevenChar'), trigger: 'blur' } ],"label":_vm.$t('login.businessRegistrationNo')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.businessRegistrationNoDesc')},model:{value:(_vm.data.number),callback:function ($$v) {_vm.$set(_vm.data, "number", $$v)},expression:"data.number"}})],1),_c('el-form-item',{staticClass:"hk-form-item_short",attrs:{"rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'chagne' } ],"prop":"type","label":_vm.$t('login.businessType')}},[_c('el-radio',{attrs:{"label":_vm.$t('login.freightForwarders')},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('el-radio',{attrs:{"label":_vm.$t('login.trucker')},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('el-radio',{attrs:{"label":_vm.$t('login.racsf')},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('el-radio',{attrs:{"label":_vm.$t('login.ra')},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('el-radio',{staticClass:"hk-type-other",attrs:{"label":"other"},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}},[_vm._v(" "+_vm._s(_vm.$t("login.others"))+" "),_c('el-input',{staticClass:"hk-input_short",attrs:{"placeholder":_vm.$t('common.fillIn')},model:{value:(_vm.data.typeOther),callback:function ($$v) {_vm.$set(_vm.data, "typeOther", $$v)},expression:"data.typeOther"}})],1)],1),_c('el-form-item',{staticClass:"hk-form-item_checkbox",attrs:{"label":_vm.$t('login.companyType')}},[_c('el-radio',{attrs:{"label":_vm.$t('login.limitedCompany')},model:{value:(_vm.data.companyType),callback:function ($$v) {_vm.$set(_vm.data, "companyType", $$v)},expression:"data.companyType"}}),_c('el-radio',{attrs:{"label":_vm.$t('login.soleProprietorship')},model:{value:(_vm.data.companyType),callback:function ($$v) {_vm.$set(_vm.data, "companyType", $$v)},expression:"data.companyType"}})],1),_c('el-row',[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"mail_address_id","rules":[
					      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.mailingAddress')}},[_c('el-select',{attrs:{"placeholder":_vm.$t('common.areaDesc')},model:{value:(_vm.data.mail_address_id),callback:function ($$v) {_vm.$set(_vm.data, "mail_address_id", $$v)},expression:"data.mail_address_id"}},_vm._l((_vm.mailAddressOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"label-width":"0px","prop":"mail_address","rules":[
				      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{staticClass:"hk-input_right",attrs:{"placeholder":_vm.$t('login.mailingAddressDesc')},model:{value:(_vm.data.mail_address),callback:function ($$v) {_vm.$set(_vm.data, "mail_address", $$v)},expression:"data.mail_address"}})],1)],1)],1),_c('el-form-item',{attrs:{"prop":"username","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.username')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.usernameDesc')},model:{value:(_vm.data.username),callback:function ($$v) {_vm.$set(_vm.data, "username", $$v)},expression:"data.username"}})],1),_c('el-form-item',{attrs:{"prop":"contactsCn","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.contactPersonCn')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.contactPersonCnDesc')},model:{value:(_vm.data.contactsCn),callback:function ($$v) {_vm.$set(_vm.data, "contactsCn", $$v)},expression:"data.contactsCn"}})],1),_c('el-form-item',{attrs:{"prop":"contactsEn","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.contactPersonEn')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.contactPersonEnDesc')},model:{value:(_vm.data.contactsEn),callback:function ($$v) {_vm.$set(_vm.data, "contactsEn", $$v)},expression:"data.contactsEn"}})],1),_c('el-form-item',{attrs:{"prop":"phone","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.phone')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.phoneDesc')},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('el-row',[_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"prop":"delivery_address_id","label":_vm.$t('account.deliveryAddress'),"rules":[
				      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('common.areaDesc')},model:{value:(_vm.data.delivery_address_id),callback:function ($$v) {_vm.$set(_vm.data, "delivery_address_id", $$v)},expression:"data.delivery_address_id"}},_vm._l((_vm.mailAddressOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":9}},[_c('el-form-item',{attrs:{"label-width":"0px","prop":"delivery_address","rules":[
				      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{staticClass:"hk-input_right",attrs:{"placeholder":_vm.$t('login.deliveryAddressDesc')},model:{value:(_vm.data.delivery_address),callback:function ($$v) {_vm.$set(_vm.data, "delivery_address", $$v)},expression:"data.delivery_address"}})],1)],1)],1),_c('el-form-item',{attrs:{"prop":"receiveContactNameZh","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.receiveContactNameZh')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.receiveContactNameZhDesc')},model:{value:(_vm.data.receiveContactNameZh),callback:function ($$v) {_vm.$set(_vm.data, "receiveContactNameZh", $$v)},expression:"data.receiveContactNameZh"}})],1),_c('el-form-item',{attrs:{"prop":"receiveContactNameEn","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.receiveContactNameEn')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.receiveContactNameEnDesc')},model:{value:(_vm.data.receiveContactNameEn),callback:function ($$v) {_vm.$set(_vm.data, "receiveContactNameEn", $$v)},expression:"data.receiveContactNameEn"}})],1),_c('el-form-item',{attrs:{"prop":"receiveContactMobile","rules":[
		      { required: true , message:  _vm.$t('forms.required'), trigger: 'blur' } ],"label":_vm.$t('login.receiveContactMobile')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('login.receiveContactMobileDesc')},model:{value:(_vm.data.receiveContactMobile),callback:function ($$v) {_vm.$set(_vm.data, "receiveContactMobile", $$v)},expression:"data.receiveContactMobile"}})],1),_c('h5',[_vm._v(_vm._s(_vm.$t('login.pleaseUpload')))]),_c('ul',{staticClass:"hk-upload"},[_c('li',[_c('label',[_vm._v(_vm._s(_vm.$t('login.BRCopy')))]),_c('el-form-item',{attrs:{"prop":"brImagePath","label-width":"0","rules":[
      		      { required: true , message:  _vm.$t('forms.required'), trigger: 'change' } ]}},[_c('el-input',{staticStyle:{"display":"none"},model:{value:(_vm.data.brImagePath),callback:function ($$v) {_vm.$set(_vm.data, "brImagePath", $$v)},expression:"data.brImagePath"}}),_c('span',{staticClass:"upload_preview"},[(_vm.data.brImagePath)?_c('a',{attrs:{"href":_vm.data.brImagePath,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('account.view')))]):_vm._e(),(_vm.data.brImagePath)?_c('span',{staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.delBr}},[_vm._v(_vm._s(_vm.$t('account.delImg')))]):_vm._e()]),_c('el-upload',{attrs:{"action":_vm.uploadUrl,"before-upload":_vm.onBeforeUpload,"file-list":_vm.brFile,"http-request":_vm.uploadBR,"limit":1,"show-file-list":false}},[_vm._v(_vm._s(_vm.$t('login.clickToUpload')))])],1)],1),_c('li',[_c('label',[_vm._v(_vm._s(_vm.$t('login.CICopy')))]),_c('el-form-item',{attrs:{"label-width":"0"}},[_c('el-input',{staticStyle:{"display":"none"},model:{value:(_vm.data.ciImagePath),callback:function ($$v) {_vm.$set(_vm.data, "ciImagePath", $$v)},expression:"data.ciImagePath"}}),_c('span',{staticClass:"upload_preview"},[(_vm.data.ciImagePath)?_c('a',{attrs:{"href":_vm.data.ciImagePath,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('account.view')))]):_vm._e(),(_vm.data.ciImagePath)?_c('span',{staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.delCi}},[_vm._v(_vm._s(_vm.$t('account.delImg')))]):_vm._e()]),_c('el-upload',{attrs:{"action":_vm.uploadUrl,"before-upload":_vm.onBeforeUpload,"file-list":_vm.ciFile,"http-request":_vm.uploadCI,"limit":1,"show-file-list":false}},[_vm._v(_vm._s(_vm.$t('login.clickToUpload')))])],1)],1)]),_c('div',{staticClass:"hk-agreement"},[_c('el-form-item',{attrs:{"prop":"agree","label-width":"0px","rules":[
		      {type: 'array', required: true, message:  ' ', trigger: 'change' } ]}},[_c('el-checkbox',{model:{value:(_vm.data.agree),callback:function ($$v) {_vm.$set(_vm.data, "agree", $$v)},expression:"data.agree"}},[_vm._v(" "+_vm._s(_vm.$t('login.agree'))+" "),_c('a',[_c('router-link',{attrs:{"to":"/about/regist-conditions","target":"_blank"}},[_vm._v("“"+_vm._s(_vm.$t('login.termsAndConditions'))+"”")])],1),_vm._v(" "+_vm._s(_vm.$t('login.and'))+" "),_c('a',[_c('router-link',{attrs:{"to":"/about/regist-privacy","target":"_blank"}},[_vm._v("“"+_vm._s(_vm.$t('login.privacyPolicy'))+"”")])],1)])],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goPreview}},[_vm._v(_vm._s(_vm.$t('login.preview')))])],1)],1)],1),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.showUploadDialog},on:{"update:visible":function($event){_vm.showUploadDialog=$event}}},[(_vm.uploadStatus == 'success')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('login.uploadImageSuccess')))])]):_vm._e(),(_vm.uploadStatus == 'fail')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('login.uploadImageFail')))])]):_vm._e(),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showUploadDialog = false}}},[_vm._v(_vm._s(_vm.$t('common.close')))])],1)]),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.alertDialogue},on:{"update:visible":function($event){_vm.alertDialogue=$event}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.alertMsg))])]),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.alertDialogue = false}}},[_vm._v(_vm._s(_vm.$t('common.close')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }