<template>
  <div class="hk-content">
    <div class="hk-regist">
      <h4>{{ $t('login.registeredMember') }}</h4>
      <el-form label-position="left" :model="data" label-width="200px" ref="data">
        <!-- <el-form-item
          prop="name"
          :label="$t('login.loginId')"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="data.name" :placeholder="$t('login.loginIdDesc')" />
        </el-form-item> -->
        <el-form-item
          prop="companyCn"
          :label="$t('login.companyNameCn')"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="data.companyCn" :placeholder="$t('login.companyNameCnDesc')" />
        </el-form-item>

        <el-form-item
          prop="companyEn"
          :label="$t('login.companyNameEn')"
          :rules="[
				      { required: true, message: $t('forms.required'), trigger: 'blur' },
				    ]"
        >
          <el-input v-model="data.companyEn" :placeholder="$t('login.companyNameEnDesc')" />
        </el-form-item>
        <el-form-item
          prop="number"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			      { pattern: /^\d{11}$/, message: $t('forms.elevenChar'), trigger: 'blur' },
			    ]"
          :label="$t('login.businessRegistrationNo')"
        >
          <el-input v-model="data.number" :placeholder="$t('login.businessRegistrationNoDesc')" />
        </el-form-item>
        <el-form-item
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'chagne' },
			    ]"
          prop="type"
          :label="$t('login.businessType')"
          class="hk-form-item_short"
        >
          <el-radio v-model="data.type" :label="$t('login.freightForwarders')"></el-radio>
          <el-radio v-model="data.type" :label="$t('login.trucker')"></el-radio>
          <el-radio v-model="data.type" :label="$t('login.racsf')"></el-radio>
          <el-radio v-model="data.type" :label="$t('login.ra')"></el-radio>
          <el-radio v-model="data.type" label="other" class="hk-type-other">
            {{$t("login.others")}}
            <el-input
              v-model="data.typeOther"
              class="hk-input_short"
              :placeholder="$t('common.fillIn')"
            />
          </el-radio>
        </el-form-item>
        <el-form-item :label="$t('login.companyType')" class="hk-form-item_checkbox">
          <el-radio v-model="data.companyType" :label="$t('login.limitedCompany')"></el-radio>
          <el-radio v-model="data.companyType" :label="$t('login.soleProprietorship')"></el-radio>
          <!---
          <el-radio v-model="data.companyType" label="other" class="hk-company-type-other">
            {{$t("login.others")}}
            <el-input
              v-model="data.companyTypeOther"
              class="hk-input_short"
              :placeholder="$t('common.fillIn')"
              label-width="0px"
            />
          </el-radio>
          --->
        </el-form-item>
        <el-row>
          <el-col :span="9">
            <el-form-item
              prop="mail_address_id"
              :rules="[
					      { required: true, message: $t('forms.required'), trigger: 'blur' },
					    ]"
              :label="$t('login.mailingAddress')"
            >
              <el-select v-model="data.mail_address_id" :placeholder="$t('common.areaDesc')">
                <el-option
                  v-for="item in mailAddressOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              label-width="0px"
              prop="mail_address"
              :rules="[
				      { required: true, message: $t('forms.required'), trigger: 'blur' },
				    ]"
            >
              <el-input
                v-model="data.mail_address"
                class="hk-input_right"
                :placeholder="$t('login.mailingAddressDesc')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          prop="username"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.username')"
        >
          <el-input v-model="data.username" :placeholder="$t('login.usernameDesc')" />
        </el-form-item>
        <!-- 联络人资料 -->
        <el-form-item
          prop="contactsCn"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.contactPersonCn')"
        >
          <el-input v-model="data.contactsCn" :placeholder="$t('login.contactPersonCnDesc')" />
        </el-form-item>
        <el-form-item
          prop="contactsEn"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.contactPersonEn')"
        >
          <el-input v-model="data.contactsEn" :placeholder="$t('login.contactPersonEnDesc')" />
        </el-form-item>
        <el-form-item
          prop="phone"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.phone')"
        >
          <el-input v-model="data.phone" :placeholder="$t('login.phoneDesc')" />
        </el-form-item>
        <el-row>
          <el-col :span="9">
            <el-form-item
              prop="delivery_address_id"
              :label="$t('account.deliveryAddress')"
              :rules="[
				      { required: true, message: $t('forms.required'), trigger: 'blur' },
				    ]"
            >
              <el-select v-model="data.delivery_address_id" :placeholder="$t('common.areaDesc')">
                <el-option
                  v-for="item in mailAddressOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              label-width="0px"
              prop="delivery_address"
              :rules="[
				      { required: true, message: $t('forms.required'), trigger: 'blur' },
				    ]"
            >
              <el-input
                v-model="data.delivery_address"
                class="hk-input_right"
                :placeholder="$t('login.deliveryAddressDesc')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 收货联络人资料 -->
        <el-form-item
          prop="receiveContactNameZh"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.receiveContactNameZh')"
        >
          <el-input v-model="data.receiveContactNameZh" :placeholder="$t('login.receiveContactNameZhDesc')" />
        </el-form-item>
        <el-form-item
          prop="receiveContactNameEn"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.receiveContactNameEn')"
        >
          <el-input v-model="data.receiveContactNameEn" :placeholder="$t('login.receiveContactNameEnDesc')" />
        </el-form-item>
        <el-form-item
          prop="receiveContactMobile"
          :rules="[
		      { required: true , message:  $t('forms.required'), trigger: 'blur' },
		    ]"
          :label="$t('login.receiveContactMobile')"
        >
          <el-input v-model="data.receiveContactMobile" :placeholder="$t('login.receiveContactMobileDesc')" />
        </el-form-item>
        <h5>{{ $t('login.pleaseUpload') }}</h5>
        <ul class="hk-upload">
          <li>
            <label>{{ $t('login.BRCopy') }}</label>
            <el-form-item
              prop="brImagePath"
              label-width="0"
              :rules="[
      		      { required: true , message:  $t('forms.required'), trigger: 'change' },
              ]"
            >
              <el-input v-model="data.brImagePath" style="display: none;"></el-input>
              <span class="upload_preview">
                <a
                  :href="data.brImagePath"
                  v-if="data.brImagePath"
                  target="_blank"
                >{{ $t('account.view') }}</a>
                <span
                  v-if="data.brImagePath"
                  @click="delBr"
                  style="cursor: pointer;text-decoration: underline;"
                >{{$t('account.delImg')}}</span>
              </span>
              <el-upload
                :action="uploadUrl"
                :before-upload="onBeforeUpload"
                :file-list="brFile"
                :http-request="uploadBR"
                :limit="1"
                :show-file-list="false"
              >{{ $t('login.clickToUpload') }}</el-upload>
            </el-form-item>
          </li>
          <li>
            <label>{{ $t('login.CICopy') }}</label>
            <el-form-item label-width="0">
              <el-input v-model="data.ciImagePath" style="display: none;"></el-input>
              <span class="upload_preview">
                <a
                  :href="data.ciImagePath"
                  v-if="data.ciImagePath"
                  target="_blank"
                >{{ $t('account.view') }}</a>
                <span
                  v-if="data.ciImagePath"
                  @click="delCi"
                  style="cursor: pointer;text-decoration: underline;"
                >{{$t('account.delImg')}}</span>
              </span>
              <el-upload
                :action="uploadUrl"
                :before-upload="onBeforeUpload"
                :file-list="ciFile"
                :http-request="uploadCI"
                :limit="1"
                :show-file-list="false"
              >{{ $t('login.clickToUpload') }}</el-upload>
            </el-form-item>
          </li>
        </ul>
        <div class="hk-agreement">
          <el-form-item
            prop="agree"
            label-width="0px"
            :rules="[
		      {type: 'array', required: true, message:  ' ', trigger: 'change' },
		    ]"
          >
            <el-checkbox v-model="data.agree">
              {{ $t('login.agree') }}
              <a>
                <router-link
                  to="/about/regist-conditions"
                  target="_blank"
                >“{{ $t('login.termsAndConditions') }}”</router-link>
              </a>
              {{ $t('login.and') }}
              <a>
                <router-link
                  to="/about/regist-privacy"
                  target="_blank"
                >“{{ $t('login.privacyPolicy') }}”</router-link>
              </a>
            </el-checkbox>
          </el-form-item>
          <el-button type="primary" @click="goPreview">{{ $t('login.preview') }}</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog class="hk-alert" :visible.sync="showUploadDialog">
      <div v-if="uploadStatus == 'success'">
        <p>{{ $t('login.uploadImageSuccess') }}</p>
      </div>
      <div v-if="uploadStatus == 'fail'">
        <p>{{ $t('login.uploadImageFail') }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="showUploadDialog = false">{{ $t('common.close') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="alertDialogue">
      <div>
        <p>{{ alertMsg }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="alertDialogue = false">{{ $t('common.close') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HkRegist',
  data() {
    let data = this.getData()
    this.getArea()
    return {
      data: data,
      br: '',
      brFile: [],
      ciFile: [],
      config: {
        header: localStorage.getItem('userToken'),
      },
      agree: false,
      showUploadDialog: false,
      uploadStatus: 'failed',
      mailAddressOptions: [],
      brFileValidation: '',
      alertMsg: '',
      alertDialogue: false,
      uploadUrl: this.$store.state.config.apiRoot + '/api/upload/image',
    }
  },
  methods: {
    delBr() {
      this.$store.commit('delBr')
      this.brFile = []
    },
    delCi() {
      this.$store.commit('delCi')
      this.ciFile = []
    },
    goPreview() {
      let self = this
      let validate = false
      this.$refs['data'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      if (self.data.type == 'other') {
        self.data.type = self.data.typeOther
      }
      if (self.data.companyType == 'other') {
        self.data.companyType = self.data.companyTypeOther
      }
      this.$store.commit('register', self.data)
      this.$router.push({
        name: 'registPreview',
      })
    },
    onBeforeUpload(file) {
      if (
        !(
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/png' ||
          file.type == 'application/pdf'
        )
      ) {
        this.alertMsg = this.$t('login.uploadFormatCheckWPDF')
        this.alertDialogue = true
        return false
      }
      if (file.size / 1024 / 1024 > 3) {
        this.alertMsg = this.$t('login.uploadSizeCheck')
        this.alertDialogue = true
        return false
      }
    },
    uploadBR(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.uploadDialogToggle(response.data)
          self.brImagePath = f
          self.$store.commit('brUpload', response.data.data.url)
          self.brFileValidation = response.data.data.url
        })
        .catch(function (error) {
          self.$notify.error({
            title: '上傳失敗',
            message: error,
            showClose: true,
          })
        })
    },
    uploadCI(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.uploadDialogToggle(response.data)
          self.ciImagePath = response.data.data.url
          self.$store.commit('ciUpload', response.data.data.url)
        })
        .catch(function (error) {
          self.$notify.error({
            title: '上傳失敗',
            message: error,
            showClose: true,
          })
          console.log(error)
        })
    },
    uploadDialogToggle(response) {
      let self = this
      self.showUploadDialog = true
      let code = response.code
      switch (code) {
        case 0:
          self.uploadStatus = 'success'
          break
        default:
          self.uploadStatus = 'fail'
          break
      }
    },
    getArea() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/address/areas?lang=' + self.$store.state.config.language
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.mailAddressOptions = response.data.data
        })
        .catch(function (error) {})
    },
    getData() {
      let data = this.$store.state.data.user
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/regist';
</style>
<style >
.hk-agreement .is-error span {
  color: red !important;
}
.el-form-item__content {
  padding-left: 30px;
}
.hk-input_short.el-input {
  margin-left: 30px !important;
}
.hk-alert {
  height: 600px !important;
}
.hk-alert .hk-alert__button {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.hk-alert .el-dialog__body {
  padding: 1rem !important;
}
.el-select {
  width: 3rem !important;
}
.hk-upload {
  display: block !important;
}
.hk-upload label {
  display: block !important;
}
</style>
